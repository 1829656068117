@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-HeavyItalic.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-HeavyItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-Black.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-Heavy.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-LightItalic.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-LightItalic.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-Light.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-Bold.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-Light.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-BoldItalic.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-BoldItalic.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-Heavy.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt Book';
  src: url('assets/fonts/SanaSansAlt-BookItalic.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-BookItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt Book';
  src: url('assets/fonts/SanaSansAlt-Book.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-HeavyItalic.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-HeavyItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-LightItalic.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-Light.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-Bold.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-BlackItalic.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-BlackItalic.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-HeavyItalic.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-HeavyItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt Book';
  src: url('assets/fonts/SanaSansAlt-BookItalic.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-BookItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-Bold.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt Book';
  src: url('assets/fonts/SanaSansAlt-BookItalic.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-BookItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-Black.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-Black.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-Heavy.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-BlackItalic.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-BoldItalic.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt Book';
  src: url('assets/fonts/SanaSansAlt-Book.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt Book';
  src: url('assets/fonts/SanaSansAlt-Book.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-Medium.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-Medium.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-Medium.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-MediumItalic.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-MediumItalic.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-Regular.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-Thin.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-Thin.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-RegularItalic.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-Regular.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-Regular.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-RegularItalic.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-Thin.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-MediumItalic.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-ThinItalic.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-RegularItalic.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-ThinItalic.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: url('assets/fonts/SanaSansAlt-ThinItalic.woff2') format('woff2'),
  url('assets/fonts/SanaSansAlt-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
