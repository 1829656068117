@use 'themes' as *;

@use '@nebular/theme/styles/globals' as *;
@import "fonts";

@include nb-install() {
  @include nb-theme-global();
};

/* You can add global styles to this file, and also import other style files */


/* You can add global styles to this file, and also import other style files */
body {
  margin: 0;
  font-family: 'Sana Sans Alt', serif !important;
}

nb-toast {
  width: 22rem !important;
}


.nephos-ui-display-data {
  .toolbar {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1.25rem;
    button {
      margin-left: 1.25rem;
    }
  }
  .card--container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }


  nb-card {
    width: 360px;
    margin: 8px;
    border-radius: 10px;
    &:hover {
      cursor: pointer;
    }
  }

  .activity--header {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
  }
  .card-item-title {
    font-weight: 700;
  }
}

.nephos-ui-sidebar-form,  {
  height: 100%;
  .error--container {
    display: flex;
    flex-direction: column;
  }
  .input--error {
    font-size: .75rem;
    color: var(--color-danger-500);
  }
  .form-group {
    margin-bottom: 1rem;
    .label {
      font-size: .75rem;
      line-height: 1rem;
      font-weight: 700;
      display: inline-block;
      margin-bottom: 0.5rem;
    }
  }
  nb-card {
    margin-bottom: 0;
    height: 92%;
  }
  nb-card-header {
    height: 73px;
    display: flex;
    align-items: center;
  }
  nb-card-body.items-col {
    align-items: stretch;
    display: flex;
    flex-direction: column;
  }
  nb-card-footer {
    display: flex;
    justify-content: flex-end;
    button {
      margin-left: 8px;
    }
  }
}

.nephos-ui-sidebar-form {
  height: 100%;
  .error--container {
    display: flex;
    flex-direction: column;
  }
  .input--error {
    font-size: .75rem;
    color: var(--color-danger-500);
  }
  .form-group {
    margin-bottom: 1rem;
    .label {
      font-size: .75rem;
      line-height: 1rem;
      font-weight: 700;
      display: inline-block;
      margin-bottom: 0.5rem;
    }
  }
  nb-card {
    margin-bottom: 0;
    height: 92%;
  }
  nb-card-header {
    height: 73px;
    display: flex;
    align-items: center;
  }
  nb-card-body.items-col {
    align-items: stretch;
    display: flex;
    flex-direction: column;
  }
  nb-card-footer {
    display: flex;
    justify-content: flex-end;
    button {
      margin-left: 8px;
    }
  }
}

nb-spinner {
  z-index: 1039 !important;
}


.custom-card {
  .display-block {
    display: block!important;
  }
  .settings {
    position: absolute;
    right: 8px;
    top: 8px;
  }
  margin: 10px;
  width: 366px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.87);
  box-shadow: 0 0 24px rgba(91, 136, 197, 0.48);
  border-radius: 10px;
  cursor: pointer;
  .color-avatar {
    width: 40px;height: 40px;background: red;border-radius: 50%;
  }
  .header-title {
    font-weight: bold;
    font-size: 16px;
    color: #222;
    margin-left: 15px;
  }
  .infos {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    .details {
      display: flex;
      align-items: center;
      .title {
        width: 11rem;
        margin-right: 8px;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 25px;
        color: var(--color-primary-500);
      }
      .detail {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 25px;
        color: #222;
      }
    }
  }

}
.application-errors-modal {
  nb-dialog-container {
    width: 560px;
  }
}

nb-context-menu {
  max-width: 600px !important;
}


table {
  width: 100%;
}
.mat-row .mat-cell {
  cursor: pointer;
}


.mat-row:hover .mat-cell {
  border-bottom: 1px solid var(--color-primary-500) !important;
}


.mat-mdc-row .mat-mdc-cell {
  border-bottom: 1px solid transparent;
  border-top: 1px solid transparent;
  cursor: pointer;
}

h.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-style: none !important;
}

@media (max-width:890px)  {
  .desktop {
    display: none !important;
  }
}
@media (min-width:890px)  {
  .mobile {
    display: none !important;
  }
}

mat-bottom-sheet-container {
  background: #151b22;
}


th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  padding: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: #30363d;
}

tr.mat-row, tr.mat-footer-row {
  height: 62px !important;
}



